import React, {useState, useEffect} from "react"
import { css } from "@emotion/react"
import Customnavbar from "./customnavbar"
import Footer from "./footer"
import CookieModal from "./cookiemodal"
import * as cookieHelpers from '../utils/cookiehelpers'


export default function Layout({ children, siteTitle }) {

  // ### MODAL ###
  // zuerst States definieren, auf dessen Basis ein Coditional Rendering des Modals stattfindet
  const [showCookieModal, setShowCookieModal] = useState(true);
  // nun mit useEffects den wert für showCookieModal definieren (wird immer ausgeführt, wenn sich showCookieModal ändert)
  useEffect(() => {
    // falls der acceptCookie gesetzt ist, setze showCookieModal auf false und umgekehrt
    setShowCookieModal(!cookieHelpers.isCookieAcceptSet())
  }, [showCookieModal])
  // wenn im Modal irgendein Button geklickt wird, dann setze showCookieModal auf false
  // das setzen des Cookies passiert im Button selbst
  // eigentlich wäre das streng genommen gar nicht nötig, da showCookieModal sowieso von useEffects gesetzt wird
  // aber wir brauchen zumindest eine state-Änderung, um ein Rerender zu provizieren
  const handleModalCloseClick = () => {
    setShowCookieModal(false)
  }

  // ### STYLES ###

  const pageHeading = css`
    margin-top: 4rem;
    margin-bottom: 4rem;
    text-align: center !important;
    font-size: 3.0rem;
    font-weight: 300;
    line-height: 1.2;
  `

  return (
    <div>
      <Customnavbar></Customnavbar>
      <div css={pageHeading}>{siteTitle}</div>
      <div>{children}</div>
      <Footer></Footer>
      {/* Zeige das CookieModal nur an, wenn showCookieModal = true ist */}
      {showCookieModal && <CookieModal onCloseClick={handleModalCloseClick}></CookieModal>}
    </div>
  )
}

