import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <div className="mb-4">
      <footer className="pt-4 my-md-2 pt-md-2 border-top">
        <div className="d-flex bd-highlight mx-4">
          <div className="p-2 flex-grow-1 bd-highlight">
            <small className="d-block mb-3 text-muted">
              &copy; 2021 klitzeklein&magisch
            </small>
          </div>
          <div className="p-2 bd-highlight">
            <Link to="/privacy/" className="text-muted text-decoration-none">
              Datenschutz
            </Link>
          </div>
          <div className="p-2 bd-highlight">
            <Link to="/imprint/" className="text-muted text-decoration-none">
              Impressum
            </Link>
          </div>
        </div>
      </footer>
    </div>
  )
}
