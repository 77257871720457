import React from "react"
import {Modal, Button} from 'react-bootstrap'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCookieBite } from '@fortawesome/free-solid-svg-icons'
import * as cookieHelpers from '../utils/cookiehelpers'

export default function CookieModal(props) {

  // diese Funktion wird aufgerufen, wenn der Button "Akzeptieren" gedrückt wird
  const handleBtnAccept = () => {
    // zuerst acceptCookies Cookie setzen
    cookieHelpers.setCookie("acceptCookies", true)
    // führe die Callback aus der Prop aus, damit das Modal durch Conditional-Rendering geschlossen werden kann
    props.onCloseClick()
  }

  // diese Funktion wird aufgerufen, wenn der Button "Nur notwendige Cookies" gedrückt wird
  const handleBtnDecline = () => {
    // zuerst acceptCookies Cookie setzen
    cookieHelpers.setCookie("acceptCookies", false)
    // führe die Callback aus der Prop aus, damit das Modal durch Conditional-Rendering geschlossen werden kann
    props.onCloseClick()
  }

  return (
    <div>
    <Modal aria-labelledby="contained-modal-title-vcenter" centered show={true} backdrop="static">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p className="text-center"><FontAwesomeIcon icon={faCookieBite} size="2x"/></p>
        <p>
          Wir verwenden Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse des Nutzungsverhaltens. 
          Damit möchten wir die Benutzererfahrung der Seite verbessern. 
          Durch die weitere Nutzung unserer Website, stimmst du der Nutzung von Cookies gemäß unserer <Link to="/privacy">Datenschutzrichtlinie</Link> zu.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" size="sm" onClick={handleBtnDecline}>Nur notwendige Cookies</Button>
        <Button onClick={handleBtnAccept}>Zustimmen</Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}