// in dieser Datei befinden sich alle Helper Funktionen, um mit Cookies umzugehen

// liefert den Inhalt eines Cookies mit dem Namen cookieName zurück 
export function getCookie(cookieName) {
  // this function loops through all cookies to find
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// setzt ein neues Cookie mit dem Namen cookieName, dem Wert cookieValue welches in exdays abläuft
export function setCookie(cookieName, cookieValue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

// löscht das Cookie mit dem Namen cookieName
export function deleteCookie(cookieName) {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

// gibt true zurück, wenn acceptCookies bereits gesetzt wurde
export function isCookieAcceptSet() {
  if (getCookie("acceptCookies")) {
    return true;
  }
  else {
    return false;
  }
}