import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import ImgLogo from "../images/customnavbar/logo.png"

// durch activeStyle wird der Stil definiert, der angezeigt wird, wenn der Link aktiv ist
// wir uns also auf der Seite befinden
// die Information auf welcher Seite sich der User gerade befindet, wird von Gatsby-<Link> automatisch ausgewertet

export default function Customnavbar(props) {
  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="white"
        variant="light"
        className="p-3 border-bottom box-shadow"
      >
        <Link to="/">
          <Navbar.Brand>
            <img
              src={ImgLogo}
              width="150"
              height="50"
              className="img-responsive"
              alt="klitzeklein & magisch"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Link to="/" className="nav-link" activeStyle={{ color: "black" }}>
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/about/" className="nav-link" activeStyle={{ color: "black" }}>
                Über mich
              </Link>
            </Nav.Item>
            <Nav.Item>
              <button onClick={() => {navigate('/#portfolio')}} className="nav-link" activeStyle={{ color: "black"}} style={{cursor: "pointer", border: "none", backgroundColor: "transparent"}}>
                Portfolio
              </button>
            </Nav.Item>
            <Nav.Item>
              {/* Wir übergeben hier einen aktuellen timestamp, der über Date.now() produziert wird
              an die prop props.location.state.timestamp der Child-Komponente
              Das ist erforderlich, damit bei einem zweiten Klick auf den Link die Ursprungsseite resettet wird */}
              <Link to="/contact/" className="nav-link" state={{timestamp: Date.now()}} activeStyle={{ color: "black" }}>
                Kontakt
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}
